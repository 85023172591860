.header-title {
  font-size: 2.5rem; /* Default font size for desktop */
}

.header-subtitle {
  font-weight: 300;
  font-size: 38px;
}

.header-text {
  font-size: 1rem;
}

/* Media query for tablets and small desktops */
@media (max-width: 992px) {
  .header-title {
    font-size: 2rem;
  }

  .header-subtitle {
    font-size: 24px;
  }

  .header-text {
    font-size: 0.875rem;
  }
}

.header_human {
  width: 44rem !important;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .header-title {
    font-size: 1.75rem;
  }

  .header-subtitle {
    font-size: 24px;
  }

  .header-text {
    font-size: 0.75rem;
  }
}
