.navbar-right-logo {
    height: 35px;
    margin-left: 30px;
}

@media (max-width: 992px) {
    .navbar-right-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        text-align: center;
    }

    .nav-item {
        margin-top: 15px;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 10px;
    }
    
    .navbar {
        text-align: center;
    }
}
