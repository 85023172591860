body {
  font-family: "Be Vietnam Pro", Arial, sans-serif;
  padding: 0;
  margin: 0;
}

.header img {
  width: 100%;
  margin-bottom: 20px;
}

.about-us .about-content,
.our-clinic .clinic-content {
  flex: 1;
  padding: 15px;
}

.about-us img,
.our-clinic img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.best-treatment .row .col-md-4 img,
.promotions .row .col-md-4 img {
  width: 100%;
  border-radius: 10px;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.btn-img {
  max-width: 300px;
  height: auto;
}

.basic-color {
  color: #1c7464;
}

.footer-icon {
  height: 24px;
}

@media (max-width: 767.98px) {
  .container {
    padding: 15px;
  }

  .header .container {
    padding: 20px;
  }

  .header h1 {
    font-size: 24px;
  }

  .header p {
    font-size: 16px;
  }

  .navbar-nav {
    text-align: center;
  }

  .navbar-nav .nav-item {
    margin-bottom: 10px;
  }

  .promotions .carousel-item > div {
    display: none;
  }

  .promotions .carousel-item > div:first-child {
    display: block;
  }

  .about-us .about-content,
  .our-clinic .clinic-content,
  .best-treatment,
  .promotions,
  .footer {
    padding: 15px;
  }

  .btn-img {
    max-width: 100%;
  }

  .text-md-end {
    text-align: left !important;
    margin-top: 20px;
  }

  .col-md-6.mb-3.mb-md-0 {
    margin-bottom: 1rem !important;
  }

  .footer-icon {
    height: 18px; /* Ubah ukuran ikon pada tampilan mobile */
  }
}

/* CSS tambahan untuk menyesuaikan padding di berbagai elemen */
.container {
  padding: 20px;
}

/* .header .container {
  padding-top: 60px;
  padding-bottom: 60px;
} */

.about-us,
.our-clinic,
.best-treatment,
.promotions,
.footer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.lokasi__cabang {
  position: relative;
  left: -140px;
}
